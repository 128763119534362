<template>
<div>
    <transition name="fade" mode="out-in">
      <router-view v-if="$store.state.notLoading" />
    </transition>
  <Footer />
  <LoadingPage />
</div>
</template>

<script>
import NavBar from "@/components/main/NavBar.vue";
import Footer from "@/components/main/Footer.vue";
import LoadingPage from '@/components/main/LoadingPage.vue';
export default {
  name: "Home",
  components: { NavBar, Footer, LoadingPage },
};
</script>